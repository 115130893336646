<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div class="row">
        <div class="col-1" style="height: 48px;">
          <b-button
            size="sm"
            variant="primary"
            @click="
              () => {
                $router.go(-1);
              }
            "
          >
            <i class="icon-arrow-left"></i> Назад
          </b-button>
        </div>
        <div class="col-8 text-center">
          <i v-if="isPageLoading" class="fa fa-cog fa-spin fa-4x"></i>
          <div v-if="isPageLoading" id="overlay"></div>
        </div>
      </div>
      <b-form-group
        id="itemName"
        label="Название предмета"
        label-for="itemName"
      >
        <div class="input-group mb-1">
          <b-form-input
            id="title"
            type="text"
            v-model="item.Title"
            trim
          ></b-form-input>
        </div>
      </b-form-group>

      <b-form-group id="itemPhoto" label="Фото" label-for="itemPhoto">
        <image-uploader
          id="itemPhoto"
          name="itemPhoto"
          :debug="1"
          :maxWidth="1024"
          :quality="0.7"
          :autoRotate="true"
          outputFormat="string"
          :preview="false"
          capture="environment"
          accept="image/*"
          doNotResize="['gif']"
          @input="setImage"
          @onUpload="isPageLoading = true"
          @onComplete="isPageLoading = false"
        ></image-uploader>
        <img :src="image" width="150" style="margin: 10px;" />
      </b-form-group>

      <b-form-group label="Описание" description="">
        <textarea
          class="form-control"
          id="itemDescText"
          name="itemDescText"
          style="min-height: 56px"
          rows="2"
          placeholder="Добавьте описание к предмету (необязательно)"
          v-model="item.Desc"
          trim
        >
        </textarea>
      </b-form-group>

      <b-form-group label="Тип предмета" description="">
        <b-form-radio-group
          id="itemType"
          v-model="item.ItemType"
          :options="types"
          name="itemType"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Базовое расположение" description="">
        <b-form-radio-group
          id="itemPlace"
          v-model="item.Place"
          :options="places"
          name="itemPlace"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Привязка к локации" description="">
        <multiselect
          v-model="item.LocationId"
          :options="locations"
          placeholder="Введите локацию"
          class="form-control"
          :multiple="false"
          :showNoResults="true"
          :searchable="true"
          :loading="isLocationsLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="50"
          @search-change="asyncFindLocation"
          :allow-empty="false"
          :showLabels="false"
          label="Title"
          return="Id"
          track-by="Id"
        >
          <span slot="noResult">Локация не найдена</span>
        </multiselect>
      </b-form-group>

      <b-form-group label="Принадлежность" description="">
        <b-form-radio-group
          id="itemOwner"
          v-model="item.Owner"
          :options="owners"
          name="itemOwner"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="Активный?"
        description="Предмет можно дизактивировать, чтобы он нигде не отображался"
      >
        <b-form-checkbox v-model="item.IsActive" name="check-button" switch>
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Виртуальный?"
        description="'Виртуальные' предметы нужны для составления списка цен, например, аптечки"
      >
        <b-form-checkbox v-model="item.IsVirtual" name="check-button">
        </b-form-checkbox>
      </b-form-group>

      <b-form-group label="Расположение" description="GPS">
        <b-button size="sm" variant="success" @click="addMarkModalShow">
          <i class="icon-circle-check"></i> Добавить расположение
        </b-button>
        <div>
          <Map
            v-if="item.GPSId > 0"
            :center="{ lat: item.GPS.Latitude, lng: item.GPS.Longitude }"
            :zoom="17"
            map-type-id="hybrid"
            style="width: 500px; height: 300px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false
            }"
          >
            <GmapMarker
              :position="position"
              :clickable="true"
              :draggable="false"
              @click="center = position"
            />
          </Map>
        </div>
      </b-form-group>

      <b-button v-b-toggle.itemComment class="m-1"
        >Добавить комментарий</b-button
      >
      <b-collapse id="itemComment">
        <b-form-group label="Комментарий" description="">
          <textarea
            class="form-control"
            id="itemCommentText"
            name="itemCommentText"
            style="min-height: 56px"
            rows="2"
            placeholder="Добавьте комментарий к предмету (необязательно)"
            v-model="item.Comment"
            trim
          >
          </textarea>
        </b-form-group>
      </b-collapse>
    </div>
    <hr />
    <div class="row">
      <div class="col-1" style="height: 48px;">
        <b-button
          size="sm"
          variant="success"
          :disabled="item.Title.length < 3"
          @click="save"
        >
          <i class="icon-circle-check"></i> Сохранить
        </b-button>
      </div>
    </div>

    <b-modal
      ref="gpsModal"
      size="lg"
      no-stacking
      ok-only
      ok-disabled
      :title="'Добавление GPS-метки'"
    >
      <GPSAdd v-on:markToParent="onGpsAdded"></GPSAdd>
    </b-modal>

    <b-modal
      ref="codeModal"
      size="md"
      ok-only
      no-stacking
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="$router.push({ name: 'Предметы' })"
      :title="'Предмет сохранен'"
    >
      <div class="d-block" v-if="confirmData">
        Код для предмета "{{ item.Title }}" (ID = {{ confirmData.Id }}):
        <h1 class="text-center">{{ confirmData.Code }}</h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import API from "../../../api/stalk.net";
import "blueimp-canvas-to-blob";
import Multiselect from "vue-multiselect";
import GPSAdd from "@/components/GPSAdd";
import { gmapApi, Map, Marker as GmapMarker } from "vue2-google-maps";

export default {
  name: "ItemEdit",
  components: {
    Multiselect,
    GPSAdd,
    Map,
    GmapMarker
  },
  data() {
    let item = {
      //default value for creation
      Id: 0,
      Title: "",
      Comment: "",
      Desc: "",
      ItemType: API.consts.ItemTypes.Quest,
      Place: API.consts.ItemPlaces.Storage,
      Owner: API.consts.ItemOwners.Own,
      Image: "",
      IsActive: true,
      IsVirtual: false,
      Data: "",
      LocationId: 0,
      GPSId: 0
    };

    const types = API.getItemTypeVariants();
    const places = API.getItemPlacesVariants();
    const owners = API.getItemOwnersVariants();

    return {
      isPageLoading: false,
      isLocationsLoading: false,
      locations: [],
      position: {},
      selectedLocation: {},
      item: item,
      image: "",
      types: types,
      places: places,
      owners: owners,
      confirmData: {
        Id: 0,
        Code: ""
      }
    };
  },
  computed: {
    google: gmapApi
  },
  filters: {},
  methods: {
    loadItem(id) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .getItemById(id)
        .then(response => {
          self.item = response.data;
          self.image = API.baseURL + self.item.Image.Path;
          self.item.Image = "";

          self.preparePosition();

          self.isPageLoading = false;
        })
        .then(() => {
          self.asyncFindLocation();
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    setImage(output) {
      let self = this;
      self.item.Image = output;
      self.image = output;
    },
    save() {
      let self = this;

      self.isPageLoading = true;
      API.private
        .createOrUpdateItem(self.item)
        .then(response => {
          self.confirmData = response.data;
          console.log(self.confirmData);

          self.isPageLoading = false;

          self.$forceUpdate();
          self.$refs.codeModal.show();
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    asyncFindLocation(query) {
      let self = this;
      self.isLocationsLoading = true;
      API.public
        .findLocations(query)
        .then(response => {
          self.locations = response.data;
          self.isLocationsLoading = false;
        })
        .catch(e => {
          console.error(e);
          self.showRegError({ message: e });
          self.isLocationsLoading = false;
        });
    },
    preparePosition() {
      let self = this;
      console.log(self.item.GPS);
      if (self.item.GPSId > 0 && self.item.GPS) {
        self.item =
          self.google &&
          new self.google.maps.LatLng(
            self.item.GPS.Latitude,
            self.item.GPS.Longitude
          );
      }
    },
    addMarkModalShow() {
      this.$refs["gpsModal"].show();
    },
    onGpsAdded(mark) {
      let self = this;

      self.$refs["gpsModal"].hide();

      self.item.GPSId = mark.Id;
      self.item.GPS = mark;

      self.preparePosition();
    }
  },
  beforeMount: function() {
    let self = this;

    if (parseInt(self.$route.params.id) !== 0) {
      self.loadItem(self.$route.params.id);
    }
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
<style
  src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
<style local>
.multiselect__tags,
.multiselect__input,
.multiselect__input:placeholder-shown,
.multiselect__tags-wrap,
.multiselect__single,
.option__img,
.multiselect__option,
.multiselect__option--highlight,
.multiselect__element,
.multiselect__content {
  background-color: #515b65;
  color: #e4e7ea;
  border: 0px solid;
}
</style>
<style scoped>
#overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}
</style>
